import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: 'AIzaSyD3HyWC5-3bF7J2TEV2R42QMEPqLQ1-ER0',
    authDomain: 'evemetro-15f5f.firebaseapp.com',
    projectId: 'evemetro-15f5f',
    storageBucket: 'evemetro-15f5f.appspot.com',
    messagingSenderId: '859912028995',
    appId: '1:859912028995:web:9e295a7b6aa929105f25f6',
    measurementId: 'G-XEDJM5F5PR'
}

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
}
const analytics = firebase.analytics

export { firebase, analytics }
